<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
        :button="viewHasData"
        :button-title="$t('Hedging.Button.New.Title')"
        button-to="HedgingStrategyNew"
      />
    </v-row>

    <v-row>
      <v-col cols="12">
        <view-data-container
          :button-title="$t('Hedging.Button.New.Title')"
          :loading="isLoadingData"
          :no-data-title="$t('Hedging.Message.NoData.Title')"
          button
          button-to="HedgingStrategyNew"
          class="pa-0"
        >
          <v-row
            justify="space-between"
          >
            <v-col cols="6">
              <v-tabs
                v-model="activeTab"
                active-class="active-tab"
                background-color="transparent"
                height="30"
                hide-slider
                show-arrows
              >
                <v-tab>{{ $t('Hedging.Tab.Archive.Title') }}</v-tab>
              </v-tabs>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
            >
              <v-text-field
                v-model="searchTerms"
                :placeholder="$t('Common.Search.Title')"
                background-color="white"
                dense
                height="28"
                hide-details="auto"
                outlined
                clearable
                prepend-inner-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
          <template v-if="viewHasData">
            <v-row>
              <v-col
                class="pa-0"
                cols="12"
              >
                <v-tabs-items v-model="activeTab">
                  <v-tab-item>
                    <v-container fluid>
                      <v-row>
                        <template v-for="(item, index) in items">
                          <v-col
                            :key="`tab-${activeTab}-items-${index}`"
                            cols="12"
                            lg="4"
                            md="6"
                            style="padding: 10px"
                          >
                            <v-lazy
                              :key="`lazy-hedging-archive-tab-${activeTab}-items-${index}`"
                              :options="{
                                threshold: .5
                              }"
                              transition="fade-transition"
                            >
                              <hedging-archive-item-card
                                :base-currency-symbol="item.BaseCurrencyDisplayName"
                                :card-event="true"
                                :date-created="item.CreatedAtFmt"
                                :pie-values="item.PieValues"
                                :tags="item.TagsTrimmed"
                                :title-value="item.Name || ''"
                                @card-click="onItemCardClick(item)"
                                @archive-item-delete-button-click="onItemCardDeleteClick(item)"
                              />
                            </v-lazy>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>

            <v-row v-if="totalPages > 1">
              <v-col>
                <v-pagination
                  v-model="page"
                  color="synthesis-ui-blue-01"
                  :length="totalPages"
                  total-visible="7"
                  @input="handlePageChange"
                />
              </v-col>
            </v-row>
          </template>
        </view-data-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import API                    from '@/api/Api'
import Auth                   from '@/mixins/auth'
import i18nRouteMeta          from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader          from '@/theme/default/components/common/SectionHeader'
import ViewDataContainer      from '@/theme/default/components/common/ViewDataContainer'
import HedgingArchiveItemCard from '@/theme/default/components/common/HedgingArchiveItemCard'
import viewHasData            from '@/mixins/viewHasData'
import HedgingArchiveResource from '@/api/resources/hedging/HedgingArchiveResource'

import { collect } from '@/lib/utils/array'
import { clone }   from '@/lib/utils/helper'

export default {
  name      : 'Hedging',
  components: {
    HedgingArchiveItemCard,
    ViewDataContainer,
    SectionHeader
  },
  directives: {},
  mixins    : [Auth, i18nRouteMeta, viewHasData],
  props     : {},
  dataStore : {
    items             : 'Hedging.Archive.Data',
    hedgingSummaryData: 'Hedging.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      searchTerms : '',
      page        : 1,
      itemsPerPage: 9,
      totalPages  : 0,
      activeTab   : null,
      policyId    : null,
      policyName  : ''
    }
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Hedging.Title'),
        disabled: true
      }]
    },

    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {
    searchTerms (newVal) {
      clearTimeout(this.searchDebounceTimeout)
      this.searchDebounceTimeout = setTimeout(() => {
        this.page = 1
        if (newVal === null || newVal.length > 2 || newVal.length === 0) {
          this.getData()
        }
      }, 500)
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getRequestParams (accountId, keyword, page, itemsPerPage) {
      const params = {}
      if (accountId) {
        params.accountId = accountId
      }
      if (keyword && keyword.trim()) {
        params.keyword = keyword.trim()
      }
      if (page) {
        params.page = page - 1
      }
      if (itemsPerPage) {
        params.itemsPerPage = itemsPerPage
      }
      return params
    },

    handlePageChange (value) {
      this.page = value
      this.getData()
    },

    getData () {
      this.isLoadingData = true
      const params = this.getRequestParams(this.accountId, this.searchTerms, this.page, this.itemsPerPage)

      API.Resource.Hedging.ArchivePaginated(params)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        this.totalPages = data?.listPoliciesPaginated?.pageCount
        if (this.totalPages < this.page) {
          this.handlePageChange(this.totalPages)
          return
        }
        const list = data?.listPolicies || data?.listAllPolicies || data?.listPoliciesPaginated?.policies || null
        if (list) {
          const o = clone(list)

          this.items = collect(o, HedgingArchiveResource)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick (item) {
      this.$router.push({
        name  : 'HedgingStrategyShow',
        params: {
          id        : item.Id,
          policyId  : item.Id,
          policyName: item.Name
        }
      })
    },

    onItemCardDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Hedging.Tab.Archive.Confirm.Delete.Title'),
        this.$t('Hedging.Tab.Archive.Confirm.Delete.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Hedging.DeletePolicy(item.Id)
            .then(response => {
              this.handleDeletePolicyResponse(response)
            })
            .catch(e => {
              this.handleDeletePolicyResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },
    handleDeletePolicyResponse (response) {
      const policyId = API.responseData(response)?.deletePolicy || ''
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && policyId) {
        this.deleteStorageDataById(policyId)
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    deleteStorageDataById (policyId) {
      if (this.policyId === policyId) {
        this.policyId = null
        this.policyName = ''
      }
      this.$delete(this.hedgingSummaryData, policyId)
      this.$delete(this.$dataStore.Hedging.Archive.Data, policyId)
      this.$delete(this.$dataStore.Hedging.Compare.Data, policyId)
    }
  }
}
</script>

<style scoped>
/deep/ .v-pagination__item, /deep/ .v-pagination__navigation {
  box-shadow: none !important;
  background-color: transparent !important;
}
/deep/ .v-pagination__item:not(.v-pagination__item--active):hover, /deep/ .v-pagination__navigation:hover {
  background-color: var(--v-synthesis-ui-blue-07-base) !important;
}
</style>
