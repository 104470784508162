<template>
  <view-data-container
    :button-title="$t('Risks.Button.New.Title')"
    :loading="isLoadingData"
    :no-data-title="$t('Risks.Message.NoArchiveData.Title')"
  >
    <v-row v-if="items && items.length">
      <template
        v-for="(item, i) in items"
      >
        <v-col
          :key="`tab-archive-items-${i}`"
          cols="12"
          lg="4"
          md="6"
          style="padding: 10px"
        >
          <v-lazy
            :key="`lazy-risk-archive-tab-items-${i}`"
            :options="{threshold: .5}"
            transition="fade-transition"
          >
            <risk-archive-item-card
              :active-analysis="item.Active"
              :base-currency-symbol="item.BaseCurrencyDisplayName"
              :card-event="true"
              :date-created="item.CreatedAtFmt"
              :pie-values="item.PieValues"
              :tags="item.TagsTrimmed"
              :title="item.Name"
              :title-prefix="$t('Dashboard.Block.LiveRisk.Label.Risk')"
              :title-prefix-tooltip="$t('Dashboard.Block.LiveRisk.InfoTip.Risk')"
              :title-value="item.TotalRisk || ''"
              @card-click="onItemCardClick(item)"
              @archive-item-active-button-click="onItemCardActiveClick(item)"
              @archive-item-delete-button-click="onItemCardDeleteClick(item)"
              @archive-item-new-button-click="onItemCardNewClick(item)"
            />
          </v-lazy>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col v-if="totalPages > 1">
        <v-pagination
          v-model="page"
          color="synthesis-ui-blue-01"
          :length="totalPages"
          total-visible="7"
          @input="handlePageChange"
        />
      </v-col>
    </v-row>
  </view-data-container>
</template>

<script>

import RiskArchiveItemCard                 from '@/theme/default/components/common/RiskArchiveItemCard'
import API                                 from '@/api/Api'
import { clone }                           from '@/lib/utils/helper'
import { collect }                         from '@/lib/utils/array'
import RiskArchiveAnalysisResource         from '@/api/resources/risks/RiskArchiveAnalysisResource'
import ViewDataContainer                   from '@/theme/default/components/common/ViewDataContainer'
import viewHasData                         from '@/mixins/viewHasData'
import RiskAnalysisNewFromExistingResource from '@/api/resources/risks/RiskAnalysisNewFromExistingResource'
import RiskAnalysisModel                   from '@/api/models/risks/RiskAnalysisModel'

export default {
  name      : 'RisksAnalysisArchive',
  components: {
    ViewDataContainer,
    RiskArchiveItemCard
  },
  directives: {},
  mixins    : [viewHasData],
  props     : {
    searchTerms: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    items               : 'Risks.Archive.Data',
    riskActiveData      : 'Risks.Active.Data',
    riskSummaryData     : 'Risks.Summary.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      page           : 1,
      itemsPerPage   : 9,
      totalPages     : 0,
      breadcrumbItems: [{
        text    : this.$t('Risks.Title'),
        disabled: false
      }]
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {
    searchTerms (newVal) {
      clearTimeout(this.searchDebounceTimeout)
      this.searchDebounceTimeout = setTimeout(() => {
        this.page = 1
        if (newVal === null || newVal.length > 2 || newVal.length === 0) {
          this.getData()
        }
      }, 500)
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getRequestParams (accountId, keyword, page, itemsPerPage) {
      const params = {}
      if (accountId) {
        params.accountId = accountId
      }
      if (keyword && keyword.trim()) {
        params.keyword = keyword.trim()
      }
      if (page) {
        params.page = page - 1
      }
      if (itemsPerPage) {
        params.itemsPerPage = itemsPerPage
      }
      return params
    },

    handlePageChange (value) {
      this.page = value
      this.getData()
    },

    getData () {
      this.isLoadingData = true
      const params = this.getRequestParams(this.accountId, this.searchTerms, this.page, this.itemsPerPage)

      API.Resource.Risks.ArchivePaginated(params)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },

    handleResponse (response) {
      const result = API.responseData(response)
      this.totalPages = result?.listAnalysesSearch?.pageCount || 1
      if (this.totalPages < this.page) {
        this.handlePageChange(this.totalPages)
        return
      }
      const data = result?.listAnalyses || result?.listAllAnalyses || result?.listAnalysesSearch?.riskAnalyses || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)

        // Store data to DataStore
        this.items = collect(o, RiskArchiveAnalysisResource)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick (item) {
      this.$router.push({
        name  : 'RisksAnalysisShow',
        params: {
          id              : item.Id,
          riskAnalysisId  : item.Id,
          riskAnalysisName: item.Name
        }
      })
    },

    /**
     * Set Analysis as active
     *
     * @param item
     */
    onItemCardActiveClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Risks.Tab.Archive.Confirm.Active.Title'),
        this.$t('Risks.Tab.Archive.Confirm.Active.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-green-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Risks.Tab.Archive.Confirm.Active.ButtonYes') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Risks.SetActiveAnalysis(item.Id)
            .then(response => {
              this.handleSetActiveAnalysisResponse(response)
            })
            .catch(e => {
              this.handleSetActiveAnalysisResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },

    handleSetActiveAnalysisResponse (response) {
      const data = API.responseData(response)?.activeAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.riskActiveData = null
        this.activeTab = 0

        this.$nextTick(() => {
          this.getData()
        })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * Create an Analysis from existing one
     *
     * @param item
     */
    onItemCardNewClick (item) {
      API.Resource.Risks.NewRiskAnalysisFromExisting(item.Id)
        .then(response => {
          this.handleNewRiskAnalysisFromExistingResponse(response)
        })
        .catch(e => {
          this.handleNewRiskAnalysisFromExistingResponse(e.response)
        })
        .finally(() => {})
    },

    handleNewRiskAnalysisFromExistingResponse (response) {
      const data = API.responseData(response)?.findAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const initData = new RiskAnalysisModel(new RiskAnalysisNewFromExistingResource(data))

        this.$router.push({
          name  : 'RisksAnalysisNew',
          params: { item: initData }
        })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * Delete non-active Analysis
     *
     * @param item
     */
    onItemCardDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Risks.Tab.Archive.Confirm.Delete.Title'),
        this.$t('Risks.Tab.Archive.Confirm.Delete.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Risks.DeleteAnalysis(item.Id)
            .then(response => {
              this.handleDeleteAnalysisResponse(response)
            })
            .catch(e => {
              this.handleDeleteAnalysisResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },

    handleDeleteAnalysisResponse (response) {
      const riskAnalysisId = API.responseData(response)?.deleteAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && riskAnalysisId) {
        this.deleteStorageDataById(riskAnalysisId)

        this.$nextTick(() => {
          this.getData()
        })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    deleteStorageDataById (riskAnalysisId) {
      if (this.riskActiveData && (this.riskActiveData?.Id === riskAnalysisId)) {
        this.riskActiveData = null
      }
      this.$delete(this.riskSummaryData, riskAnalysisId)
      this.$delete(this.riskBaseCurrencyData, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Correlations.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Seasonality.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Forecasts.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Exposures.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.ValueAtRisk.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.HistoricalSpectrum.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.ForwardSimulations.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.ExtremeEvents.Data, riskAnalysisId)
    }
  }
}
</script>

<style scoped>
/deep/ .v-pagination__item, /deep/ .v-pagination__navigation {
  box-shadow: none !important;
  background-color: transparent !important;
}
/deep/ .v-pagination__item:not(.v-pagination__item--active):hover, /deep/ .v-pagination__navigation:hover {
  background-color: var(--v-synthesis-ui-blue-07-base) !important;
}
</style>
