<template>
  <v-col cols="12">
    <v-row class="mb-5">
      <v-col
        cols="12"
        lg="12"
        md="12"
      >
        <block-item-card
          v-if="valueAtRisk && valueAtRisk.ValueAtRisk"
          ref="valueAtRiskCard"
          :base-currency-symbol="riskBaseCurrencyDisplayName"
          :export-element="elementValueAtRisk"
          :title="$t('Risks.Section.ValueAtRisk.Block.ValueAtRisk.Title')"
          :title-large="false"
          :title-value="valueAtRisk.ValueAtRisk.Value"
          :tooltip="$t('Risks.Section.ValueAtRisk.Block.ValueAtRisk.InfoTip')"
        >
          <v-lazy
            :options="{threshold: .5}"
            transition="fade-transition"
          >
            <base-e-chart-bar
              :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
              :axis-x-label-show="false"
              :axis-y-label-inside="false"
              :axis-y-label-margin="0"
              :axis-y-label-show="true"
              :axis-y-split-line-dash-offset="4"
              :axis-y-split-line-opacity="1"
              :axis-y-split-line-type="[8, 8]"
              :axis-y-split-number="2"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.1)"
              :chart-data-options="valueAtRisk.ValueAtRisk.Bar"
              :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 10,containLabel: true}"
              :tooltip-show="false"
              :tooltip-show-content="false"
              axis-x-label-value-format="percent"
              axis-x-tick-suffix="%"
              axis-y-label-value-format="percent"
              axis-y-tick-suffix="%"
              chart-animation-easing="quarticOut"
            />
          </v-lazy>
          <v-card-text
            v-if="valueAtRisk.ValueAtRisk.PerAsset"
            class="overflow-y-auto pb-2"
          >
            <v-container
              fluid
              pa-0
              pb-2
            >
              <v-row dense>
                <template
                  v-for="(item, i) in valueAtRisk.ValueAtRisk.PerAsset"
                >
                  <v-col
                    :key="`item-method-one-legend-${i}`"
                    :class="[{'pr-3': (i % 2 === 0) && $vuetify.breakpoint.mdAndUp},{'pl-3': (i % 2 === 1) && $vuetify.breakpoint.mdAndUp}]"
                    class="py-0"
                    cols="12"
                    :md="$vuetify.breakpoint.mdAndUp && valueAtRiskSingle ? '12' : '6'"
                  >
                    <item-chart-legend
                      :key="`value-at-risk-legend-${i}`"
                      :background-color="getColorBg(i % colors.length, .1)"
                      :base-currency-symbol="riskBaseCurrencyDisplayName"
                      :icon-color="getColor(i % colors.length)"
                      :title="item.name"
                      :title-value="item.value"
                      :expanded="item.aggregate"
                      :icon="expandedValueAtRisk[item.name] ? item.iconExpanded : item.icon"
                      @item-chart-legend-click="onValueAtRiskItemClick(item)"
                    />
                    <div
                      v-if="item.aggregate && expandedValueAtRisk[item.name]"
                      :key="`value-at-risk-legend-expanded-${i}`"
                      class="pl-4"
                    >
                      <template
                        v-for="(aggregateItem, i2) in item.aggregateItems"
                      >
                        <item-chart-legend
                          :key="`value-at-risk-legend-item-${i2}`"
                          :background-color="getColorBg(i % colors.length, .1)"
                          :base-currency-symbol="riskBaseCurrencyDisplayName"
                          :icon-color="getColor(i % colors.length)"
                          :title="aggregateItem.name"
                          :title-value="aggregateItem.value"
                          icon="mdi-circle-outline"
                        />
                      </template>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
        </block-item-card>
      </v-col>

      <v-col
        cols="12"
      >
        <block-item-card
          v-if="valueAtRisk && valueAtRisk.VolatilityPerAsset"
          :min-height="150"
          :title="$t('Risks.Section.ValueAtRisk.Block.Volatility.Title')"
          :title-large="false"
          :tooltip="$t('Risks.Section.ValueAtRisk.Block.Volatility.InfoTip')"
        >
          <v-card-text v-if="valueAtRisk.VolatilityPerAsset">
            <v-container
              fluid
              pa-0
            >
              <v-row dense>
                <template
                  v-for="(item, i) in valueAtRisk.VolatilityPerAsset"
                >
                  <v-col
                    :key="`item-chart-legend-${i}`"
                    :class="[{'pr-3': (i % 2 === 0) && $vuetify.breakpoint.mdAndUp},{'pl-3': (i % 2 === 1) && $vuetify.breakpoint.mdAndUp}]"
                    class="py-0"
                    cols="12"
                    :md="$vuetify.breakpoint.mdAndUp && valueAtRiskSingle ? '12' : '6'"
                  >
                    <item-chart-legend
                      :key="`volatility-legend-${i}`"
                      :background-color="getColorBg(i % colors.length, .1)"
                      :icon-color="getColor(i % colors.length)"
                      :title="item.name"
                      :title-value="item.value"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
        </block-item-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

import BaseEChartBar           from '@/theme/default/components/charts/echarts/BaseEChartBar'
import BlockItemCard           from '@/theme/default/components/common/BlockItemCard'
import chartsColorMixin        from '@/theme/default/components/charts/mixins/chartsColorMixin'
import ItemChartLegend         from '@/theme/default/components/common/ItemChartLegend'
import baseEChartColors        from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import riskAnalysisId          from '@/theme/default/views/risks/mixins/riskAnalysisId'
import API                     from '@/api/Api'
import { clone }               from '@/lib/utils/helper'
import RiskValueAtRiskResource from '@/api/resources/risks/RiskValueAtRiskResource'
import riskBaseCurrency        from '@/theme/default/views/risks/mixins/riskBaseCurrency'

export default {
  name      : 'MethodOneBlock',
  components: {
    ItemChartLegend,
    BlockItemCard,
    BaseEChartBar
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, baseEChartColors, riskAnalysisId],
  props     : {},
  dataStore : {
    riskValueAtRiskData : 'Risks.ValueAtRisk.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementValueAtRisk : null,
      expandedValueAtRisk: []
    }
  },
  computed: {
    valueAtRisk () {
      return this.riskValueAtRiskData[this.id] || null
    },
    valueAtRiskSingle () {
      return this.valueAtRisk.Assets.filter((asset) => asset !== 'portfolio').length === 1
    }
  },
  watch: {
    valueAtRisk () {
      this.setExportElements()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.ValueAtRisk(this.id).then(response => {
        this.handleResponse(response)
      }).catch(e => {
        this.handleResponse(e.response)
      })
    },
    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)

        if (o) {
          const v = new RiskValueAtRiskResource(o)

          this.$set(this.riskValueAtRiskData, this.id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onValueAtRiskItemClick (item) {
      for (const key in this.expandedValueAtRisk) {
        if (key !== item.name) {
          this.expandedValueAtRisk[key] = false
        }
      }

      this.$set(this.expandedValueAtRisk, item.name, !this.expandedValueAtRisk[item.name])
    },

    getCardTextHeight () {
      const len = this.valueAtRisk.FinancialExposure.PerAsset.length
      return (52 * len) + 24
    },

    setExportElements () {
      this.$nextTick(() => {
        this.elementValueAtRisk = this.$refs?.valueAtRiskCard?.$el
      })
    }
  }
}
</script>

<style scoped>
</style>
